import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Industries from "../components/Repeating/Industries";
import Testimonials from "../components/Repeating/Testimonials";
import FAQs from "../components/Repeating/FAQs";
import Subscribe from "../components/Repeating/Subscribe";
import RecentPosts from "../components/Repeating/RecentPosts";
import CallToAction from "../components/Repeating/CTA";
import ModalTeamMembers from "../components/Modal/ModalTeamMembers";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonWithIcon from "../components/Button/ButtonWithIcon";
import { mapEdgesToNodes } from "../lib/helpers";

const Page = ({ data }) => {
    const faqs = [
        {
          question:
            "I just started my business so there’s not a lot going on just yet. Do I need a bookkeeper?",
          answer: (
            <>
              <p className="mb-0">
              Absolutely! Initial bookkeeping setup, bookkeeping flow, and strategic goal
setting/business consultations are ideal for all small businesses, but especially for
startups unfamiliar with the routines of backend accounting processes. And that can’t be more true than in the cannabis industry. So much about your initial set up will determine the path your business takes, financially speaking. It is always best to start off on the right foot if you are planning to truly build your legacy and reach your targeted goals as fast as possible. 

              </p>
            </>
          ),
        },
        {
          question: "In the past, I haven’t maintained regular books. Why should I now?",
          answer: (
            <>
              <p className="mb-0">
              The complexity of accounting needed to comply with the IRS code Section 280E is significant and time-consuming. The only way to ensure you’re going to be able to take deductions against your revenues is to maintain books regularly. Failing to do so can make it difficult to support cost of goods sold deductions to the IRS if, and when, you’re audited.
              </p>
            </>
          ),
        },
        {
          question:
            "How does Dark Horse keep my books to the standards required by the IRS to support my deductions under 280E?",
          answer: (
            <>
              <p className="mb-0">
              We review every transaction your company makes and digitally capture receipts supporting every deduction the business takes. Using these receipts as support, we can properly allocate costs under 280E, maintain digital records of every deduction on your tax return, and help to “audit-proof” your books should the IRS come calling.
              </p>
            </>
          ),
        },
        {
          question:
            "Should my books be done on a cash basis or accrual basis of accounting?",
          answer: (
            <>
              <p className="mb-0">
              For most businesses in the cannabis industry, accrual accounting is a must. In order to take tax deductions on your return, you have to follow IRS Code Section 471, which specifies that the company’s books must be kept on the accrual basis of accounting. Beyond needing accrual accounting for tax purposes, this accounting method allows you to gain deeper insight into profitability and your true performance at any point in time. It is also the foundation of being able to do forecasting, proformas, profitability analyses, and other fractional CFO-type services.
              </p>
            </>
          ),
        },
        {
            question:
              "I have a bookkeeper that costs me very little. What is the value of going with Dark Horse?",
            answer: (
              <>
                <p className="mb-4">
                The reason a bookkeeper’s pricing would be relatively cheap is that they’re doing the lowest level of bookkeeping...cash basis bookkeeping where they shove revenues and expenses in generic accounts. Often, they have taken on a lot of clients to be able to pay the bills, which causes your bookkeeping to be done well after the fact. This leaves you with books that have stale numbers that don’t give you needed insight into your business. And for some businesses – that’s perfect! But you’re a cannabis business – that makes you a “special” business in multiple ways. And it’s highly unlikely your low-cost bookkeeper has the specialized knowledge of 280E and 471 needed to support your tax deductions. 
                </p>
                <p className="mb-0">
                Dark Horse CPAs know accounting and finance (not just how to
use QuickBooks Online), so they’ll be able to provide you with financial  statements that display meaningful information on your margins and profitability on your revenue streams, locations, etc. You’ll receive this information timely and we can grow with you to help you dive deeper into the numbers to create strategic operational plans. Read a bit more about the Dark Horse difference <a href="https://darkhorse.cpa/what-great-bookkeeping-looks-like/" target="_blank">here.</a></p>
              </>
            ),
          },
          {
            question:
              "What is the ideal frequency for accurate bookkeeping?",
            answer: (
              <>
                <p className="mb-0">
                We recommend monthly or weekly bookkeeping as a best practice. However, in certain situations where transactions are very limited (such as during the initial year of a start-up) we can conduct quarterly bookkeeping services, and in instances where there is a very high volume of transactions weekly bookkeeping can be conducted. Under IRS Section 471, accrual basis accounting must be maintained on a regular basis, so annual bookkeeping should not be considered for most businesses in the cannabis industry. And keep in mind – you’re running a business! The only way you can make informed business decisions is if you have accurate and timely financial information. Quarterly and annual bookkeeping won’t provide that.
                </p>
              </>
            ),
          },
          {
            question:
              "How much does it cost?",
            answer: (
              <>
                <p className="mb-0">
                The complexity of accounting for cannabis businesses under 280E requires lots of time and expertise to complete properly. But at the end of the day, Dark Horse CPAs want to treat you like the business you are. Dark Horse CPAs don’t charge the dreaded “Green Tax.” We keep our fees for cannabis companies in line with our fees for non-cannabis companies. We don’t want to be your partner just while cannabis is “illegal” federally – we have built a practice that will continue to thrive after federal legalization and the 280E burden is lifted, because we provide industry expertise and show businesses how to be more profitable, regardless of industry. When it comes to cannabis, we price fairly. We grow as you grow (your weed).
                </p>
              </>
            ),
          },
          {
            question:
              "I have extremely complex bookkeeping needs with a large number of transactions each month. Should I be outsourcing or hiring in-house?",
            answer: (
              <>
                <p className="mb-4">
                As with any major function of your business, you’ll need to assess your ability to lead and manage any internal staff to ensure a successful outcome. Many business owners aren’t equipped to do this in the area of accounting, and thus make less-than-ideal hires, and aren’t able to set their accountants up for success. </p>

                <p className="mb-0">Alternatively, by hiring a Dark Horse CPA, you’ll be paying a fraction of the cost since we can provide our services in a much more efficient manner than most in-house accountants, thus eliminating the salary and benefits of a full-time accountant. Also, you won’t have to manage us as we’ll partner with you to deploy solutions that have been highly effective with clients in the cannabis industry. For certain businesses, it may not be an either/or approach. You may need to have in-house accounting staff supplemented by a Dark Horse Fractional CFO. Either way, we’ll let you know what we believe to be the best approach for your business.

                </p>
              </>
            ),
          },
        
      ];

  const teamMembersNodes = (data || {}).teamMembers
    ? mapEdgesToNodes(data.teamMembers)
    : [];

  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <Layout>
      <SearchEngineOptimization
        title="Cannabis Bookkeeping for Dispensaries | Dark Horse CPAs"
        description="Our CPAs will maximize your savings and provide books that help drive your business forward. Contact us today to speak about our cannabis bookkeeping services!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="mb-20 pt-2 md:mb-32 md:pt-16">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Cannabis Bookkeeping</h1>
              <p>
              Your bookkeeping should be the bedrock upon which all other financial services are built. If you have bad bookkeeping, you’re going to have bad books, bad projections, and bad tax planning. We ensure your finances are in a steady state.
              </p>
              <ButtonSolid href="/pricing-tool/" text="Get a Quote" />
            </div>
            <div className="relative order-1 md:order-2">
              <GatsbyImage image={data.hero.childImageSharp.gatsbyImageData} />
              
            </div>
          </div>
        </div>
      </section>
      <section className="relative mb-12 md:mb-12">
            <div className="container py-6">
            <div className="mx-auto mb-[20px] text-center">
            <GatsbyImage image={data.head.childImageSharp.gatsbyImageData} />
            </div>
              <h3 className="text-center mx-auto mb-10">Did you know?</h3>
              <div className="grid gap-y-0 grid-col-1 md:grid-cols-3 md:gap-x-0 lg:gap-x-0">
            <div className="relative text-center">
            <GatsbyImage image={data.fifty.childImageSharp.gatsbyImageData} className="mb-6" />
              <p>50% of small businesses fail within the first five years due to poor financial management during their startup phase</p>
            </div>
            <div className="relative text-center">
            <GatsbyImage image={data.seventy.childImageSharp.gatsbyImageData} className="mb-6"/>
             <p>70% of businesses that fail due to poor financial management have in-house bookkeeping and accounting</p>

            </div>
            <div className="relative text-center">
            <GatsbyImage image={data.forty.childImageSharp.gatsbyImageData} className="mb-6"/>
             <p>Businesses that outsource their bookkeeping experience a 40% reduction in operation costs and 4% increase in profitability</p>
            </div>
          </div>
              
            </div>
          </section>
      <section className="mb-20 pt-2 md:mb-32 md:pt-16">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-2">
              <h2>Cannabis Accounting Is Complex</h2>
              <p>
              The tax rules of 280E profoundly impact how you should be maintaining your cannabis business’ books. Maximizing your tax savings requires industry-specific knowledge. Dark Horse Cannabis CPAs will not only maximize your savings but will provide books that help drive your business forward.
              </p>
              
            </div>
            <div className="relative order-1 md:order-1">
              <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} />
              
            </div>
          </div>
        </div>
      </section>


      <section className="mb-20 pt-00 md:mb-28">
        <div className="container">
        <header className="mb-10 text-center md:mb-18">
        <h2 className="max-w-[720px] m-auto">Services Include</h2>
        
        </header>
          <div className="grid gap-y-0 grid-col-1 md:grid-cols-3 md:gap-x-0 lg:gap-x-0">
            <div className="relative">
              <ul className="md:border-l-2 px-6 border-[#CEE2E6] styled-list-plus">
                  <li>Weekly/Monthly Bookkeeping</li>
                  <li>Bill Pay (accounts payable) Outsourcing </li>
                  <li>Tax Estimate Calculations</li>
                  <li>Sales Tax Returns</li>

              </ul>
            </div>
            <div className="relative">
             <ul className="md:border-l-2 px-6 border-[#CEE2E6] styled-list-plus">
                 <li>Payroll</li>
             <li>Embedding Receipts within Financial Statements</li>
             <li>Invoicing Support & Outsourcing</li>
             <li>Chart of Accounts Customization</li>
             </ul>

            </div>
            <div className="relative">
             <ul className="md:border-x-2 px-6 border-[#CEE2E6]">
             <li>&nbsp;</li>
                 <li className="text-center">Get high on our supply (of services)!
</li>               
<li>&nbsp;</li>
                 <li className="text-center"><ButtonSolid href="/pricing-tool/" text="Get a Quote" /></li>
                 
             </ul>
            </div>
          </div>
          </div>
      </section>


      <section className="mb-20 pt-10 md:mb-20">
        
        <header className="mb-10 text-center md:mb-18">
        <h2 className="max-w-[720px] m-auto">Come One, Come All</h2>
        <p className="max-w-[640px] m-auto">We work with all businesses in the cannabis industry—from seed to sale!</p>
        </header>
          <div className="grid gap-y-0 grid-col-1 md:grid-cols-3 md:gap-x-0 lg:gap-x-0">
            <div className="relative">
              <AniLink
                fade
                to="/cannabis-industry/"
                className="group font-normal text-white no-underline"
              >
                <div className="mb-5 overflow-hidden">
                  <GatsbyImage
                    image={data.industry.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <p className="text-white uppercase text-lg absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-bold">Overall Cannabis Industry</p>
                
              </AniLink>
            </div>
            <div className="relative">
              <AniLink
                fade
                to="/cultivators-growers-manufacturers/"
                className="group font-normal text-white no-underline"
              >
                <div className="mb-5 overflow-hidden">
                  <GatsbyImage
                    image={data.cultivators.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <p className="text-white uppercase text-lg absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-bold">Cultivators & Growers</p>
               
              </AniLink>
            </div>
            <div className="relative">
              <AniLink
                fade
                to="/dispensaries-retail/"
                className="group font-normal text-white no-underline"
              >
                <div className="mb-5 overflow-hidden">
                  <GatsbyImage
                    image={data.dispensaries.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <p className="text-white uppercase text-lg absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-bold">Dispensaries</p>
                
              </AniLink>
            </div>
          </div>
       
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 text-left">
            <h2>Meet the Cannabis CPA Team</h2>
            <p className="max-w-[728px]">When you work with Dark Horse, you work with people. Get to know our people below.</p>
          </header>
          {teamMembersNodes.length > 0 && (
            <div className="grid grid-cols-2 gap-y-6 gap-x-6 md:grid-cols-3 lg:gap-x-10">
              {teamMembersNodes.map((item, i) => (
                <div key={i}>
                  <button
                    data-modal-open="modal-team-members"
                    onClick={() => setSlideIndex(i)}
                    className="group text-left font-normal no-underline"
                  >
                    <div className="mb-2.5 overflow-hidden rounded-4xl md:mb-3.5">
                      <GatsbyImage
                        image={item.headshot.asset.gatsbyImageData}
                        className="over w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                      />
                    </div>
                    <div className="text-lg font-bold text-black md:text-xl">
                      {item.name}
                    </div>
                    <div className="mb-8 text-sm font-bold uppercase tracking-wide text-primary-900/40">
                      {item.title}
                    </div>
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      <Testimonials category="cannabis" />
      <FAQs heading="Frequently Asked Questions" uniqueFaqs={faqs} />
      <section className="pt-20 pb-20 md:pb-32">
      <div className="container">
        <div className="grid items-center gap-y-8 md:grid-cols-12 md:gap-x-10 lg:gap-x-20">
        <div className="md:col-span-8 md:col-start-1">
        <GatsbyImage image={data.whyus.childImageSharp.gatsbyImageData} className="rounded-2xl"/>

          </div>
        <div className="md:col-span-4 md:col-end-13">
            <h2>Why Dark Horse?</h2>
            <p>
            We work in the industry because we are passionate about the industry. We want to see your Canna-Business succeed, and we'll do everything we can to help you along the way.
            </p>
            <ButtonWithIcon
              href="/about-us/"
              text="Meet Our People"
              altStyle={1}
            />
          </div>
          
          
        </div>
      </div>
    </section>
     
      <CallToAction />
      <ModalTeamMembers slideIndex={slideIndex} slides={teamMembersNodes} />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Fractional CFO.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Fractional CFO.jpg" }
    ) {
      publicURL
    }
    horseHead: file(relativePath: { eq: "global/logo-head-black.svg" }) {
      publicURL
    }
    horseHeadOrange: file(relativePath: { eq: "global/logo-head-orange.svg" }) {
      publicURL
    }
    head: file(relativePath: { eq: "home/horse-head.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 55)
      }
    }
    whyus: file(
        relativePath: { eq: "home/Why Dark Horse_.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
    
    rotatingWords: file(
        relativePath: { eq: "global/rotating-words-cfo-black.svg" }
      ) {
        publicURL
      }
    hero: file(
      relativePath: { eq: "home/1.0 Hero Bookkeeping.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    fifty: file(
        relativePath: { eq: "home/Group 119.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 81)
        }
      }
      forty: file(
        relativePath: { eq: "home/Group 121.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 81)
        }
      }
      seventy: file(
        relativePath: { eq: "home/Group 120.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 81)
        }
      }
    intro: file(
        relativePath: { eq: "home/Group 239.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 560)
        }
      }
    dashboard: file(
      relativePath: { eq: "Business Solutions/Fractional CFO/dashboard.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1120)
      }
    }
    cultivators: file(
        relativePath: { eq: "home/Cultivators & Manufacturers.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
      dispensaries: file(
        relativePath: { eq: "home/Dispensaries.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
      industry: file(
        relativePath: { eq: "home/Overall Cannabis Industry.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
    reports: file(
      relativePath: { eq: "Business Solutions/Fractional CFO/reports.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1120)
      }
    }
    teamMembers: allSanityTeamMember(
        filter: {industries: {elemMatch: {}}, industriesFilter: {elemMatch: {title: {eq: "Cannabis"}}}}
      ) {
        edges {
          node {
            id
            name
            headshot {
              asset {
                gatsbyImageData(layout: CONSTRAINED, width: 564)
                title
              }
            }
            directPhoneNumber
            emailAddress
            linkedinProfile
            hubspotID
            _rawBiography
            title
          }
        }
      }
  }
`;

export default Page;
